import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useLanguage } from "../../contexts/LanguageContext"; // Import the context
import { translations } from "../../i18n"; // Import all translations

const TestimonialSlider = () => {
  const { language } = useLanguage(); // Get current language
  const testimonials = translations[language].testimonials; // Fetch testimonials based on current language

  return (
    <section className="testimonial-sec py-5">
      <div className="container">
      <div className="section-title text-center position-relative pb-0 mb-4 mx-auto">
                  {/* <h5 className="fw-bold text-primary text-uppercase">What We Offer</h5> */}
                  <h2 className="mb-0 display-5">What our clients are saying</h2>
               </div>
    <div className="testimonial-slider">
      <Swiper
        modules={[Pagination]}
        spaceBetween={30}
        slidesPerView={3}
        centeredSlides={true}
        loop={true}
        pagination={{ clickable: true }}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className={`testimonial-card ${index === 1 ? "center-slide" : ""}`}>
              <img src={testimonial.img} alt={testimonial.name} className="client-img" />
              <h3>{testimonial.name}</h3>
              <p className="designation">{testimonial.designation}</p>
              <p className="review">{testimonial.review}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    </div>
    </section>
  );
};

export default TestimonialSlider;

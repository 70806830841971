import React from "react";
import { useLanguage } from "../../contexts/LanguageContext"; // Import the language context
import { Link } from "react-router-dom";

const WhatWeOffers = () => {
  // const { language } = useLanguage(); // Get the current language
  const { t } = useLanguage();
  

  return (
    <section className="what-offers py-5">
      <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto">
                  {/* <h5 class="fw-bold text-primary text-uppercase">What We Offer</h5> */}
                  <h2 class="mb-0 display-5">What We Offer</h2>
               </div>
               
               <div class="row g-5">
                  <div class="col-lg-6 col-md-6 wow zoomIn animated" data-wow-delay="0.3s">
                     <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="service-icon">
                           <i class="fa fa-code text-white"></i>
                        </div>
                        <h4 class="mb-3">Nationwide Connections:</h4>
                        <p class="m-0">Access a vast network of trusted yarn suppliers and buyers tailored to your needs.</p>
                        {/* <a class="btn btn-lg btn-primary rounded" href="#contact">
                        <i class="bi bi-arrow-right"></i>
                        </a> */}
                     </div>
                  </div>
                  <div class="col-lg-6 col-md-6 wow zoomIn animated" data-wow-delay="0.6s" >
                     <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="service-icon">
                           <i class="fas fa-pencil-ruler text-white"></i>
                        </div>
                        <h4 class="mb-3">Digital experts</h4>
                        <p class="m-0">Benefit from our digital platform for fair and transparent deals.</p>
                        {/* <a class="btn btn-lg btn-primary rounded" href="#contact">
                        <i class="bi bi-arrow-right"></i>
                        </a> */}
                     </div>
                  </div> 
               </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeOffers;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { showToast } from '../../utils/toast';
import { postApi } from '../../utils/api/api';
import DeleteImages from '../Common/DeleteImages';

const EditInventoryForm = () => {
  const baseUrl = "https://backend.tokoyarn.com/public/";
  const location = useLocation();
  const { id } = useParams();
  const { t } = useLanguage();
  const navigate = useNavigate();
  const inventoryData = location.state?.inventory || null;
  console.log("inventoryData", inventoryData);
  const [isLocal, setIsLocal] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    material: '',
    qty: '',
    qty_type: '',
    price: '',
    description: '',
    p1: '',
    p2: '',
    p3: '',
    p4: '',
    p5: '',
    p6: '',
    p7: '',
    p8: '',
    p9: '',
    p10: '',
    images: [],
    certificate: null,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const materialsOptions = ['Cotton', 'Silk', 'Wool'];
  const quantityTypeOptions = ['Kg', 'Bundle'];

  useEffect(() => {
    if (inventoryData) {
      try {
        const parsedData = JSON.parse(JSON.stringify(inventoryData));
        setFormData({ ...parsedData, images: (parsedData.images), certificate: baseUrl + inventoryData.certificate });
      } catch (error) {
        console.error('Invalid inventoryData format:', error);
        navigate('/seller-dashboard');
      }
    } else {
      navigate('/seller-dashboard');
    }
  }, [inventoryData, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, [field]: file }));
    setErrors((prev) => ({ ...prev, [field]: '' }));
  };

  const handleImageChange = (e) => {
    setIsLocal(true);
    const files = Array.from(e.target.files);
    const fileTypeValid = files.every((file) => file.type.startsWith("image/"));
    if (!fileTypeValid) {
      setErrors((prev) => ({
        ...prev,
        images: t('errors.invalidFileType', 'Only image files are allowed.'),
      }));
      return;
    }
    setFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...files],
    }));
    setErrors((prev) => ({ ...prev, images: '' }));
  };

  const handleImageDelete = (index) => {
    setFormData((prev) => {
      const updatedImages = prev.images.filter((_, i) => i !== index);
      return { ...prev, images: updatedImages };
    });
  };

  const handleCertificateDelete = () => {
    setFormData((prev) => ({ ...prev, certificate: null }));
  };
  console.log("formData::", formData);

  const validateForm = () => {
    const { name, material, qty, qty_type, price, description, images, certificate } = formData;
    const newErrors = {};

    if (!name.trim()) newErrors.name = t('errors.nameRequired', 'Product Name is required.');
    if (!material.trim()) newErrors.material = t('errors.materialRequired', 'Material is required.');
    if (!qty || isNaN(qty) || qty <= 0) newErrors.qty = t('errors.qtyInvalid', 'Enter a valid quantity.');
    if (!qty_type.trim()) newErrors.qty_type = t('errors.qtyTypeRequired', 'Quantity Type is required.');
    if (!price || isNaN(price) || price <= 0) newErrors.price = t('errors.priceInvalid', 'Enter a valid price.');
    // if (!description.trim()) newErrors.description = t('errors.descriptionRequired', 'Description is required.');
    // if (images.length === 0) newErrors.images = t('errors.imageRequired', 'Please upload at least one image.');
    if (!certificate) newErrors.certificate = t('errors.certificateRequired', 'Please upload a certificate.');

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    try {
      const payload = { ...formData };
      console.log("payload:::::::", payload);

      const formDataPayload = new FormData();
      Object.keys(payload).forEach((key) => {
        if (key === 'images') {
          let imagesArray = payload.images;

          // If payload.images is a string (JSON array), parse it to get an array of image paths
          if (typeof imagesArray === 'string') {
            imagesArray = JSON.parse(imagesArray);  // Parse the string to an array
          }

          console.log('Images Array:', imagesArray);  // Log images to ensure they're correct

          // Now handle the images correctly
          imagesArray.forEach((image) => {
            // Check if the image is a File object (i.e., a real file uploaded via an input)
            if (image instanceof File) {
              // Append the file object directly
              formDataPayload.append('images[]', image);
            } else {
              // If it's a string (URL or path), log an error or handle accordingly
              console.error('Expected file, but got a string (image path):', image);
              // If needed, you could append the image path as a string, but that's usually for non-file uploads.
              // formDataPayload.append('images[]', image);
            }
          });
        } else if (key === 'certificate') {
          if (typeof payload.certificate != 'string' || payload.certificate?.indexOf('http') == -1) {
            formDataPayload.append('certificate', payload.certificate);
          }
        } else {
          formDataPayload.append(key, payload[key] || '');
        }
      });
      if(!formDataPayload.get('certificate')) {
        formDataPayload.append('certificate', '');
      }
      if(!formDataPayload.get('images[]')) {
        formDataPayload.append('images[]', '');
      }
      console.log('formDataPayload:: ', formDataPayload.get('images'));
      await postApi(`seller/edit-product/${id}`, formDataPayload);
      showToast('success', t('success.inventoryAdded', 'Inventory updated successfully!'));
      navigate('/seller-dashboard');
    } catch (err) {
      showToast('error', err.message || t('errors.submitFailed', 'Failed to submit the form.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-wrapper py-5">
      <div className="auth-container container">
        <div className="card form-card p-4">
          <h2 className="text-primary mb-4">{t('editInventory.heading', 'Add Inventory')}</h2>
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-lg-6'>
                <div className="form-group">
                  <label className="form-label text-dark">{t('addInventory.labels.name', 'Product Name')}<sup>*</sup></label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="form-control border-0 bg-light px-4"
                  />
                  {errors.name && <small className="text-danger">{errors.name}</small>}
                </div>
              </div>
              <div className='col-lg-6'>
                <div className="form-group">
                  <label className="form-label text-dark">{t('addInventory.labels.material', 'Material')}<sup>*</sup></label>
                  <select
                    name="material"
                    value={formData.material}
                    onChange={handleInputChange}
                    className="form-control border-0 bg-light px-4"
                  >
                    <option value="">{t('addInventory.placeholders.material', 'Choose a material...')}</option>
                    {materialsOptions.map((material, index) => (
                      <option key={index} value={material}>
                        {material}
                      </option>
                    ))}
                  </select>
                  {errors.material && <small className="text-danger">{errors.material}</small>}
                </div>
              </div>
              <div className='col-lg-6'>
                <div className="form-group">
                  <label className="form-label text-dark">{t('addInventory.labels.qty', 'qty')}<sup>*</sup></label>
                  <input
                    type="number"
                    name="qty"
                    value={formData.qty}
                    onChange={handleInputChange}
                    className="form-control border-0 bg-light px-4"
                  />
                  {errors.qty && <small className="text-danger">{errors.qty}</small>}
                </div>
              </div>
              <div className='col-lg-6'>
                <div className="form-group">
                  <label className="form-label text-dark">{t('addInventory.labels.qtyType', 'Quantity Type')}<sup>*</sup></label>
                  <select
                    name="qty_type"
                    value={formData.qty_type}
                    onChange={handleInputChange}
                    className="form-control border-0 bg-light px-4"
                  >
                    <option value="">{t('addInventory.placeholders.qty_type', 'Choose a quantity type...')}</option>
                    {quantityTypeOptions.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                  {errors.qty_type && <small className="text-danger">{errors.qty_type}</small>}
                </div>
              </div>
              <div className='col-lg-12'>
                <div className="form-group">
                  <label className="form-label text-dark">{t('addInventory.labels.price', 'Price')}<sup>*</sup></label>
                  <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                    className="form-control border-0 bg-light px-4"
                  />
                  {errors.price && <small className="text-danger">{errors.price}</small>}
                </div>
              </div>
              <div className='col-lg-12'>
                <div className="form-group">
                  <label className="form-label text-dark">{t('addInventory.labels.description', 'Description')}<sup>*</sup></label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="form-control border-0 bg-light px-4"
                  ></textarea>
                  {errors.description && <small className="text-danger">{errors.description}</small>}
                </div>
              </div>
              <div className='col-lg-12'>
                <label className="form-label">Parameter</label>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p1</label>
                  <input
                    type="text"
                    name="p1"
                    value={formData.p1}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p2</label>
                  <input
                    type="text"
                    name="p2"
                    value={formData.p2}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p3</label>
                  <input
                    type="text"
                    name="p3"
                    value={formData.p3}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p4</label>
                  <input
                    type="text"
                    name="p4"
                    value={formData.p4}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p5</label>
                  <input
                    type="text"
                    name="p5"
                    value={formData.p5}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p6</label>
                  <input
                    type="text"
                    name="p6"
                    value={formData.p6}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p7</label>
                  <input
                    type="text"
                    name="p7"
                    value={formData.p7}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p8</label>
                  <input
                    type="text"
                    name="p8"
                    value={formData.p8}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p9</label>
                  <input
                    type="text"
                    name="p9"
                    value={formData.p9}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">p10</label>
                  <input
                    type="text"
                    name="p10"
                    value={formData.p10}
                    onChange={handleInputChange}
                    className="form-control bg-light"
                  />
                </div>
              </div>
              <div className='col-lg-12'>
                <div className="form-group">
                  <label className="form-label text-dark">{t('addInventory.labels.images', 'Product Images')}<sup>*</sup></label>
                  <input
                    type="file"
                    name="images[]"
                    multiple
                    onChange={handleImageChange}
                    className="form-control border-0 bg-light px-4"
                  />
                  {/* {formData.images.length > 0 && <DeleteImages
                        // key={index}
                        images={formData.images || []} 
                        onDelete={handleImageDelete}
                        // isSingle
                      />} */}
                  {/* <DeleteImages images={baseUrl + parse(inventoryData.images)} onDelete={handleImageDelete} /> */}
                  <div className='row'>
                  <div className='col-lg-6'>
                    <div className='card p-2 mt-3'>
                      <label className=''>Previous Images</label>
                  <div className='uploaded-images-list d-flex'>
                  {(inventoryData.prevImages).map((image, index) => {
                    console.log('Image:: ', image);
                    return (
                      
                      <div className="d-flex mt-2">
                      
                        <div className="mr-2">
                          <img
                            src={baseUrl + image}
                            alt="preview"
                            className="img-thumbnail"
                            style={{ width: 100, height: 100 }}
                          />
                          </div>
                          </div>
                    );
                  })}
                  </div>
                  </div>
                  </div>
                  
                  {formData.images.length > 0 ? (<div className='col-lg-6'>
                    <div className='card p-2 mt-3'>
                    <label className=''>New Images</label>
                  {formData.images.length > 0 ? (formData.images)?.map((image, index) => {
                    console.log('Image:: ', image);
                    return (
                      <DeleteImages
                        key={index}
                        images={isLocal ? image : baseUrl + image}
                        onDelete={() => handleImageDelete(index)}
                        isSingle
                      />
                    );
                  }) : ""}
                  </div>
                  </div>): ""}
                  </div>
                  
                  
                  {errors.images && <small className="text-danger">{errors.images}</small>}
                  {/* {formData.images.length === 0 && <small className="text-muted">No files selected</small>} */}
                  {errors.images && <small className="text-danger">{errors.images}</small>}
                  {/* <div className="d-flex mt-2">
                {formData.images.map((image, index) => (
                  <div key={index} className="mr-2">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="preview"
                      className="img-thumbnail"
                      style={{ width: 100, height: 100 }}
                    />
                    <button
                      type="button"
                      className="btn btn-danger btn-sm mt-1"
                      onClick={() => handleImageDelete(index)}
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                ))}
              </div> */}
                </div>
              </div>
              <div className='col-lg-12'>
                <div className="form-group certificate-file">
                  <label className="form-label text-dark">{t('addInventory.labels.certificate', 'Upload Certificate')}<sup>*</sup></label>
                  <input
                    type="file"
                    accept="application/pdf,image/*"
                    onChange={(e) => handleFileChange(e, 'certificate')}
                    className="form-control border-0 bg-light px-4"
                  />
                  {formData.certificate && (
                    <DeleteImages
                      images={formData.certificate}
                      onDelete={handleCertificateDelete}
                      isSingle
                    />
                  )}
                  {errors.certificate && <small className="text-danger">{errors.certificate}</small>}
                </div>
              </div>
              <div className='col-lg-12'>
                <button
                  type="submit"
                  className="btn btn-secondary py-3 px-5 mt-3"
                  disabled={loading}
                >
                  {loading ? t('addInventory.submitting', 'Submitting...') : t('addInventory.buttons.submit', 'Submit')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditInventoryForm;
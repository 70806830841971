import React from "react";
import { useParams } from "react-router-dom"; // To access the ID from the URL
import { useLanguage } from "../contexts/LanguageContext";
import { translations } from "../i18n"; // Import translations
import MainLayout from '../components/Layouts/MainLayout';
import InnerBanner from '../components/PagesComponents/InnerBanner';

const NewsDetail = () => {
  const { language } = useLanguage(); // Get current language
  const { id } = useParams(); // Get the ID from the URL
  const newsData = translations[language].news; // Fetch news data based on language

  // Find the news item based on the ID
  const newsItem = newsData.find((news) => news.id === parseInt(id));
  console.log("newsItem::", newsItem);

  if (!newsItem) {
    // Handle case when the news item isn't found
    return (
      <div className="container text-center py-5">
        <h1>News Not Found</h1>
        <p>The article you're looking for doesn't exist.</p>
      </div>
    );
  }

  return (
    <MainLayout>
    <InnerBanner pageKey="newsDetails"/>
    <section className="news-detail-section py-5">
      <div className="container">
        <h1 className="mb-4">{newsItem.title}</h1>
        <img
          src={newsItem.image}
          alt={newsItem.title}
          className="img-fluid mb-4"
        />
        <p className="text-muted">{newsItem.description}</p>
      </div>
    </section>
    </MainLayout>
  );
};

export default NewsDetail;
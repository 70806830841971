
import React from 'react';

const DeleteImages = ({ images, onDelete, isSingle = false }) => {
  const handleDelete = () => {
    onDelete(isSingle ? null : -1); // Pass `null` for single image or `-1` for all images.
  };
  console.log("images>>::", images);

  const getImageSource = (image) => {
    // If `image` is a string and contains 'https', return it directly.
    if (typeof image === 'string' && image.indexOf('https') > -1) {
      return image;
    }
    // If `image` is an object (e.g., File), create an object URL.
    if (image instanceof File || image instanceof Blob) {
      return URL.createObjectURL(image);
    }
    return ''; // Return an empty string if the type is unexpected.
  };

  return (
    <div className="d-flex mt-2">
      {images && (isSingle ? (
        <div className="mr-2 img-wrap">
          <img
            src={getImageSource(images)}
            alt="preview"
            className="img-thumbnail"
            style={{ width: 100, height: 100 }}
          />
          <button
            type="button"
            className="action-icon-btn btn btn-danger btn-sm mt-1"
            onClick={handleDelete}
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      ) : (
        images.map((image, index) => (
          <div key={index} className="mr-2 img-wrap">
            <img
              src={getImageSource(image)}
              alt={`preview-${index}`}
              className="img-thumbnail"
              style={{ width: 100, height: 100 }}
            />
            <button
              type="button"
              className="action-icon-btn  btn btn-danger btn-sm mt-1"
              onClick={() => onDelete(index)}
            >
             <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        ))
      ))}
    </div>
  );
};

export default DeleteImages;
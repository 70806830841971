import React from "react";
import { useLanguage } from "../../contexts/LanguageContext"; // Import the language context
import { translations } from "../../i18n"; // Import translations

const InnerBanner = ({ pageKey }) => {
  const { language } = useLanguage(); // Get the current language
  const pageData = translations[language]?.pages[pageKey]; // Fetch data for the specific page key

  return (
    <div
      className="container-fluid bg-primary py-5 inner-banner">
      <div className="row py-5">
        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
          <h1 className="display-4 text-white animated zoomIn">
            {pageData || "Page Title"} {/* Fallback to "Page Title" if no data */}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default InnerBanner;

// src/pages/Home.jsx
import React from 'react';
import { useLocation, Link } from "react-router-dom";
import MainLayout from '../components/Layouts/MainLayout';
import BannerImg from '../assets/main-banner.png';
// import TestimonialSlider from '../components/PagesComponents/TestimonialSlider';
import TestimonialSlider from '../components/PagesComponents/Testimonials';
import WhatWeOffers from '../components/PagesComponents/WhatWeOffers';

const Home = () => (
  <MainLayout>
    <section className='hero-banner'>
      <div className='container'>
        <div className='row row align-items-center'>
          <div className='col-lg-6 py-4'>
          <div className="p-3">
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">Welcome to Tokoyarn</h5>
                  <h1 className="display-4 text-white mb-md-4 animated zoomIn">The only Market you will ever need </h1>
                  <p className="text-white mb-md-4">In the ever-evolving world of textiles, finding the right yarn supplier or buyer can be a complex task. At Toko Yarn, we make it simple. As experts in yarn mediation, we connect manufacturers, suppliers, and buyers across the nation, ensuring quality, reliability, and seamless collaboration at every step.</p>
                  {/* <a href="#contact" className="btn btn-secondary py-md-3 px-md-5 me-0 animated slideInLeft">Contact Now</a> */}
              </div>
          </div>
          <div className='col-lg-6'>
            <img src={BannerImg} alt='Hero Banner' />
          </div>
        </div>
      </div>
    </section>

    <WhatWeOffers/>

    <section className='Why-choose-sec'>
      <div className='container'>
      <div class="section-title text-left position-relative pb-0 mb-3 mx-auto">
                  {/* <h5 class="fw-bold text-primary text-uppercase">What We Offer</h5> */}
                  <h2 class="mb-0 display-5">Why Choose Us?</h2>
               </div>
              
               <ul className='cstm-list'>
                <li>Integrity First: We prioritize trust and transparency in every transaction.</li>
                <li>Industry Expertise: Years of experience ensure we understand the nuances of the yarn and textile markets.</li>
                <li>Seamless Process: From initialisation to final delivery, we manage the details so you can focus on your business.
                Whether you're a yarn manufacturer seeking reliable buyers or a textile producer in need of premium yarns, Toko Yarn is here to help you succeed.
                </li>
                
               </ul>
      </div>
    </section>

    <section className='Why-choose-sec'>
      <div className='container'>
      <div class="section-title text-left position-relative pb-0 mb-3 mx-auto">
                  {/* <h5 class="fw-bold text-primary text-uppercase">What We Offer</h5> */}
                  <h2 class="mb-0 display-5">Get Started Today</h2>
               </div>
              
               <h6>Explore how Toko Yarn can help streamline your yarn sourcing and sales. <Link to="/register"> Register today </Link> and learn more about our services.</h6>
      </div>
    </section>

    <TestimonialSlider/>
  </MainLayout>
);

export default Home;

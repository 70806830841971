import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getApi, deleteApi, postApi } from '../../utils/api/api';
import { showToast } from '../../utils/toast';
import { useNavigate } from 'react-router-dom';
import { showConfirmationAlert } from '../../utils/confirmationAlert';
import { useLanguage } from '../../contexts/LanguageContext';

const ExistingInventory = () => {
  const { t } = useLanguage();
  const baseUrl = "https://backend.tokoyarn.com/public/";
  const { user } = useAuth();
  const navigate = useNavigate();
  const [inventories, setInventories] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch inventories
  const fetchInventories = async () => {
    setLoading(true);
    try {
      const response = await getApi(`seller/get-inventory?userId=${user.user_data.id}`);
      for (const inventory of response.data.product.data) {
        if(inventory.images && inventory.images.length) {
          inventory.prevImages = JSON.parse(inventory.images);
          inventory.images = [];
        }
      }
      console.log('response:: ', response)
      setInventories(response.data.product.data);
    } catch (err) {
      // showToast('error', err.message || 'Failed to fetch inventory.');
    } finally {
      setLoading(false);
    }
  };

  // Handle delete
  const handleDelete = async (id) => {
    showConfirmationAlert({
      message: t("confirmation.delete", "Are you sure you want to delete?"),
      onConfirm: async() => {
        try {
          await deleteApi(`seller/delete-inventory/${id}`);
          showToast('success', 'Inventory deleted successfully!');
          setInventories((prev) => prev.filter((inventory) => inventory.id !== id));
        } catch (err) {
          showToast('error', err.message || 'Failed to delete inventory.');
        }
      },
      onCancel: () => {
        console.log("Delete cancelled");
      },
    });
    
  };

  useEffect(() => {
    fetchInventories();
  }, []);

  return (
    <div className="container py-5">
    <h2 className="text-primary mb-4">Existing Inventory</h2>
    {loading ? (
      <p>Loading...</p>
    ) : inventories.length > 0 ? (
      <div className="row">
        {inventories.map((inventory) => (
          <div className="col-md-12 mb-4" key={inventory.id}>
            <div className="card p-3">
            <div className='row'>
              <div className='col-lg-4'>
              <div className="image-grid">
                {inventory.prevImages && (inventory.prevImages).length ?

                  <img 
                  key={0} 
                  src={`${baseUrl+ (inventory.prevImages)[0]}`} 
                  alt={`Product ${0 + 1}`} 
                  className="img-fluid mb-2" 
                  /> : ''

                }
                {/* {inventory.images && JSON.parse(inventory.images).map((image, index) => (
                  <img key={index} src={image} alt={`Product ${index + 1}`} className="img-fluid mb-2" />
                ))} */}
              </div>
              </div>
              <div className='col-lg-8'>
              <h4>{inventory.name}</h4>
              <div className='product-specs'>
              <p>Material: {inventory.material}</p>
              <p>Quantity: {inventory.qty} {inventory.qty_type}</p>
              <p>Price: {inventory.price}</p>
              {inventory.P1 ? <p>P1: {inventory.P1 || '-'}</p> : ''}
              {inventory.P2 ? <p>P2: {inventory.P2 || '-'}</p> : ''}
              {inventory.P3 ? <p>P3: {inventory.P3 || '-'}</p> : ''}
              {inventory.P4 ? <p>P4: {inventory.P4 || '-'}</p> : ''}
              </div>
              <p>Description: {inventory.description}</p>
              
              <div className="action-btn">
                <button
                  className="btn btn-secondary mb-2"
                  onClick={() => navigate(`/seller-dashboard/${inventory.id}` , { state: { inventory } })}
                >
                  <i class="fas fa-pencil-alt"></i>
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(inventory.id)}
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
            </div>
            </div>

          </div>
        ))}
      </div>
    ) : (
      <p>No inventories found.</p>
    )}
  </div>
  );
};

export default ExistingInventory;
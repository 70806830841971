

// src/pages/Home.jsx
import React from 'react';
import MainLayout from '../components/Layouts/MainLayout';
import InnerBanner from '../components/PagesComponents/InnerBanner';
import LoginForm from '../components/Auth/LoginForm';

const Login = () => (
  <MainLayout>
    <InnerBanner pageKey="login" />
     <LoginForm/>
  </MainLayout>
);

export default Login;





// src/pages/Home.jsx
import React from 'react';
import MainLayout from '../components/Layouts/MainLayout';

const NotFound = () => (
  <MainLayout>
    <h1>NotFound Page</h1>
  </MainLayout>
);

export default NotFound;

// src/pages/Home.jsx
import React from 'react';
import MainLayout from '../components/Layouts/MainLayout';
import InnerBanner from '../components/PagesComponents/InnerBanner';
import FirstTimePasswordChangeForm from '../components/Auth/FirstTimePasswordChangeForm';

const FirstTimePasswordChange = () => {
  return(
  <MainLayout>
    <InnerBanner pageKey="firstTimePasswordChange" />
    <FirstTimePasswordChangeForm/>
  </MainLayout>
);
}

export default FirstTimePasswordChange;

import React from "react";
import { useLanguage } from "../../contexts/LanguageContext"; // Import the language context
import { translations } from "../../i18n"; // Import translations
import importImage from "../../utils/images/importImage";

const AboutUs2 = () => {
  const secImage2 = importImage("section-image2.jpg");
  const { language } = useLanguage(); // Get the current language
  const aboutUsData = translations[language]?.about2; // Fetch the About Us content

  if (!aboutUsData) {
    return <p>Loading...</p>; // Fallback in case content isn't loaded
  }

  return (
    <section id="about" className="py-5">
      <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="row g-5">
          <div className="col-lg-5" style={{ minHeight: "300px" }}>
              <div className="position-relative h-100">
                <img
                  src={secImage2}
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  style={{ objectFit: "cover" }}
                  alt="About Us"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                {/* <h5 className="fw-bold text-primary text-uppercase">
                  {aboutUsData.sectionTitle}
                </h5> */}
                <h1 className="mb-0">{aboutUsData.heading}</h1>
              </div>
              <p className="mb-4">{aboutUsData.description}</p>
              <div className="row g-4">
                {/* <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <div className="ms-0">
                      <h5 className="mb-2">{aboutUsData.ourStory.title}</h5>
                      <p className="mb-0">{aboutUsData.ourStory.content}</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <div className="ms-0">
                      <h5 className="mb-2">{aboutUsData.ourApproach.title}</h5>
                      <p className="mb-0">{aboutUsData.ourApproach.content}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <a
                href={aboutUsData.exploreLink}
                className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                data-wow-delay="0.9s"
              >
                {aboutUsData.exploreText}
              </a> */}
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs2;

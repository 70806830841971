import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";
import { translations } from "../i18n";
import InnerBanner from "../components/PagesComponents/InnerBanner";
import ProductSlider from "../components/Common/ProductSlider";
import { showToast } from "../utils/toast";  // Assuming showToast is a utility function for displaying messages
import { postApi } from "../utils/api/api"; // Assuming postApi is a utility function for making POST requests

const ProductDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { language } = useLanguage(); // Get selected language
  const product = location.state?.product || null; // Get product data from URL state
  console.log("product::", product);

  const [value, setValue] = useState(""); // State to store the bid amount
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!product) {
      showToast("error", "Product not found!"); // Display an error if the product is not found
    }
  }, [product]);

  if (!product) return <div>Product not found!</div>;

  const handleBidSubmit = async (e) => {
    e.preventDefault();

    if (!value || isNaN(value) || value <= 0) {
      showToast("error", "Please enter a valid bid amount.");
      return;
    }

    setLoading(true);
    
    try {
      // Prepare the payload for the bid API
      const payload = {
        // productId: product.id,
        value: value,
      };

      // Call the bid API
      const response = await postApi(`buyer/bid-product/${id}`, payload); // Replace with actual API endpoint

      if (response.success) {
        showToast("success", "Your bid has been submitted successfully!");
        navigate(`/buyer-dashboard/buyer-bids`);
      } else {
        showToast("error", "Failed to submit bid. Please try again.");
      }
    } catch (error) {
      showToast("error", error.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <InnerBanner pageKey="bid" />
      <section className="product-detail py-5">
        <div className="container">
          <div className="row">
            {/* Left Column - Image Slider */}
            <div className="col-lg-6">
            {product.images && JSON.parse(product.images).length ?
              <ProductSlider images={product.images} /> : ''

              }
              {/* <ProductSlider images={product.images} /> */}
            </div>

            {/* Right Column - Details and Bid Form */}
            <div className="col-lg-6">
              <h2>{product.name}</h2>
              <p>
                <strong>Price:</strong> {product.price}
              </p>
              <form onSubmit={handleBidSubmit} className="mb-4">
                <label htmlFor="value" className="form-label">
                  Enter Bid Amount:
                </label>
                <input
                  type="number"
                  id="value"
                  className="form-control"
                  placeholder="Enter your bid"
                  value={value}
                  onChange={(e) => setValue(e.target.value)} // Update state when bid changes
                />
                <button type="submit" className="btn btn-primary mt-3" disabled={loading}>
                  {loading ? "Submitting..." : "Submit Bid"}
                </button>
              </form>
              <h4>Description:</h4>
              <p>{product.description}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetail;
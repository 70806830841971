
// src/utils/toast.js
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import required CSS for toast

// Show error toast
export const showToast = (status, message) => {
  if (message) {
    toast[status](message, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored', // Optional: Use "dark" or "light" themes if needed
    });
  } else {
    console.error('Error toast was triggered without a message.');
  }
};


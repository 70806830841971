import React, { useState } from 'react';
import { useLanguage } from "../../contexts/LanguageContext";
import { useNavigate } from 'react-router-dom';
import { postApi } from '../../utils/api/api';
import importImage from "../../utils/images/importImage";
import { showToast } from '../../utils/toast';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import default styles for react-phone-input-2

const RegisterForm = () => {
  const LoginFormImage = importImage("login-form.jpg");
  const navigate = useNavigate();
  const { t } = useLanguage();
  const [formData, setFormData] = useState({
    name: '',
    c_name: '',
    c_type: '',
    email: '',
    phone_number: '',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value, countryData) => {
    // Extract the country code without the '+' sign
    const countryCode = countryData.dialCode;
    // Remove '+' sign from the beginning
    const phoneNumberWithoutPlus = value.startsWith('+') ? value.slice(1) : value;
    // Replace the country code in the final number format
    const fullNumber = phoneNumberWithoutPlus.startsWith(countryCode)
      ? phoneNumberWithoutPlus
      : `${countryCode}${phoneNumberWithoutPlus}`;
  
    setFormData({ ...formData, phone_number: fullNumber });
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.name) {
      formErrors.name = t('errors.nameRequired');
      isValid = false;
    }

    if (!formData.c_name) {
      formErrors.c_name = t('errors.companyNameRequired');
      isValid = false;
    }

    if (!formData.c_type) {
      formErrors.c_type = t('errors.companyTypeRequired');
      isValid = false;
    }

    if (!formData.email) {
      formErrors.email = t('errors.emailRequired');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = t('errors.invalidEmail');
      isValid = false;
    }

    if (!formData.phone_number) {
      formErrors.phone_number = t('errors.phoneRequired');
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    try {
      const message = await postApi('/user-register', formData); // Send all form data to register API
      showToast('success', message.message || t('register.success', 'Registration successful!'));
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } catch (err) {
      // showToast('error', err.message || t('register.error', 'Registration failed.'));
      const apiErrors = err.response?.data?.data || {};
      const formattedErrors = {};

      for (const [field, messages] of Object.entries(apiErrors)) {
        formattedErrors[field] = messages.join(', ');
      }

      setErrors((prevErrors) => ({ ...prevErrors, ...formattedErrors }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-wrapper py-5">
      <div className="auth-container container">
        <div className='row'>
          <div className='col-lg-7'>
            <div className='card form-card p-4'>
              <h2 className='text-primary mb-4'>{t('register.heading', 'Register')}</h2>

              <form onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='form-group'>
                      <label className="form-label text-dark">
                        {t('register.nameLabel', 'Name')}<sup>*</sup>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className='form-control border-0 bg-light px-4'
                        value={formData.name}
                        onChange={handleChange}
                        maxLength={245}
                      />
                      {errors.name && <span className="text-danger">{errors.name}</span>}
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='form-group'>
                      <label className="form-label text-dark">
                        {t('register.companyNameLabel', 'Company Name')}<sup>*</sup>
                      </label>
                      <input
                        type="text"
                        name="c_name"
                        className='form-control border-0 bg-light px-4'
                        value={formData.c_name}
                        onChange={handleChange}
                        maxLength={245}
                      />
                      {errors.c_name && <span className="text-danger">{errors.c_name}</span>}
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='form-group'>
                      <label className="form-label text-dark">
                        {t('register.companyTypeLabel', 'Company Type')}<sup>*</sup>
                      </label>
                      <select
                        name="c_type"
                        className="form-control border-0 bg-light px-4"
                        value={formData.c_type}
                        onChange={handleChange}
                      >
                        <option value="">{t('register.selectCompanyType', 'Select Company Type')}</option>
                        <option value="IT">{t('register.companyTypeIT', 'IT')}</option>
                        <option value="Finance">{t('register.companyTypeFinance', 'Finance')}</option>
                        <option value="Healthcare">{t('register.companyTypeHealthcare', 'Healthcare')}</option>
                        <option value="Other">{t('register.companyTypeOther', 'Other')}</option>
                      </select>
                      {errors.c_type && <span className="text-danger">{errors.c_type}</span>}
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='form-group'>
                      <label className="form-label text-dark">
                        {t('register.emailLabel', 'Email')}<sup>*</sup>
                      </label>
                      <input
                        type="email"
                        name="email"
                        className='form-control border-0 bg-light px-4'
                        value={formData.email}
                        onChange={handleChange}
                        maxLength={245}
                      />
                      {errors.email && <span className="text-danger">{errors.email}</span>}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label text-dark">
                        {t('register.phoneLabel', 'Phone Number')}<sup>*</sup>
                      </label>
                      <PhoneInput
                        country={'id'} // Default country set to Indonesia
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handlePhoneChange}
                        inputClass="form-control border-0 bg-light"
                        countryCodeEditable={false} // Disable editing the country code
                      />
                      {errors.phone_number && <span className="text-danger">{errors.phone_number}</span>}
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <button type="submit" className='btn btn-secondary py-3 px-5 mt-3' disabled={loading}>
                      {loading ? t('buttons.registering') : t('buttons.register')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className='col-lg-5'>
            <img src={LoginFormImage} alt='Login Form' className='form-image' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
// src/pages/Home.jsx
import React from 'react';
import MainLayout from '../components/Layouts/MainLayout';
import InnerBanner from '../components/PagesComponents/InnerBanner';
import RoleCard from '../components/PagesComponents/RoleCard';

const Role = () => {
  return(
  <MainLayout>
    <InnerBanner pageKey="role" />
     <RoleCard/>
  </MainLayout>
);
}

export default Role;

// src/pages/Home.jsx
import React from 'react';
import MainLayout from '../components/Layouts/MainLayout';
import InnerBanner from '../components/PagesComponents/InnerBanner';
import ForgotPasswordForm from '../components/Auth/ForgotPasswordForm';

const ForgotPassword = () => {
  return(
  <MainLayout>
    <InnerBanner pageKey="forgotPassword" />
     <ForgotPasswordForm/>
  </MainLayout>
);
}

export default ForgotPassword;

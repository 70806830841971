
import React from 'react';
import MainLayout from '../components/Layouts/MainLayout';
import InnerBanner from '../components/PagesComponents/InnerBanner';
import RegisterForm from '../components/Auth/RegisterForm';

const Register = () => (
  <MainLayout>
    <InnerBanner pageKey="register" />
     <RegisterForm/>
  </MainLayout>
);

export default Register;


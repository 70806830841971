import React, { useState } from "react";
import { useLanguage } from "../contexts/LanguageContext";
import { translations } from "../i18n";
import MainLayout from '../components/Layouts/MainLayout';
import InnerBanner from '../components/PagesComponents/InnerBanner';

const News = () => {
  const { language } = useLanguage(); // Get current language
  const newsData = translations[language].news; // Fetch news data based on language
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  // Pagination logic
  const totalPages = Math.ceil(newsData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentNews = newsData.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <MainLayout>
        <InnerBanner pageKey="news"/>
    <section className="news-section py-5">
      <div className="container">
        <h2 className="text-center mb-4">Latest News</h2>
        <div className="row">
          {currentNews.map((news, index) => (
            <div className="col-md-4 mb-4" key={index}>
              <div className="card h-100">
                <img src={news.image} className="card-img-top" alt={news.title} />
                <div className="card-body">
                  <h5 className="card-title">{news.title}</h5>
                  <p className="card-text">{news.description}</p>
                  <a href={`/news-detail/${news.id}`} className="btn btn-primary" rel="noopener noreferrer">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <nav aria-label="News pagination">
          <ul className="pagination justify-content-center">
            {[...Array(totalPages)].map((_, i) => (
              <li
                key={i}
                className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                onClick={() => handlePageChange(i + 1)}
              >
                <button className="page-link">{i + 1}</button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </section>
    </MainLayout>
  );
};

export default News;
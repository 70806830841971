// src/utils/api/api.js
import axios from 'axios';
import { showToast } from '../toast';

const API_BASE_URL = 'https://backend.tokoyarn.com/api';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  // headers: { 'Content-Type': 'application/json'},
});

// Generic error handler
const handleError = (error) => {
  const message = error.response?.data?.message || 'Something went wrong';
  const status = error.response?.status || 'unknown';
  console.error(`Error (${status}): ${message}`);
  showToast('error',message); // Show error toast
  throw error; // Rethrow error to handle at the calling function
};

export const checkAuth = async () => {
  try {
    var getUserData = localStorage.getItem('user');
    if (getUserData) {
      getUserData = JSON.parse(getUserData);
      const token = getUserData ? getUserData.token : null;
      if (token) {
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        console.log(apiClient.defaults.headers);
      } else {
        delete apiClient.defaults.headers.common['Authorization'];
      }
    } else {
      delete apiClient.defaults.headers.common['Authorization'];
    }
  } catch (error) {
    handleError(error); // Generic error handling
  }
}

export const postApi = async (endpoint, data) => {
  try {
    checkAuth();
    const response = await apiClient.post(endpoint, data);
    return response.data; // Return the full API response
  } catch (error) {
    handleError(error); // Generic error handling
    // return error;
  }
};

export const getApi = async (endpoint, data) => {
  try {
    checkAuth();
    const response = await apiClient.get(endpoint, data);
    return response.data; // Return the full API response
  } catch (error) {
    handleError(error); // Generic error handling
  }
};

export const putApi = async (endpoint, data) => {
  try {
    checkAuth();
    const response = await apiClient.put(endpoint, data);
    return response.data; // Return the full API response
  } catch (error) {
    handleError(error); // Generic error handling
  }
};

export const deleteApi = async (endpoint, data) => {
  try {
    checkAuth();
    const response = await apiClient.delete(endpoint, data);
    return response.data; // Return the full API response
  } catch (error) {
    handleError(error); // Generic error handling
  }
};
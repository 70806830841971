import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from "../../contexts/LanguageContext";
import { postApi } from '../../utils/api/api'
import importImage from "../../utils/images/importImage";
import { toast } from 'react-toastify'; // Import Toast

const FirstTimePasswordChangeForm = () => {
  const LoginFormImage = importImage("login-form.jpg");
  const { t } = useLanguage();
  const [formData, setFormData] = useState({
    email: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setError(t('errors.passwordMismatch'));
      toast.error(t('errors.passwordMismatch')); // Error toast
      return;
    }

    try {
      setLoading(true);
      setError('');
      setSuccess('');
      const response = await postApi('/first-password-change', {
        password: formData.password
      });
      setSuccess(response.message || t('success.passwordChange'));
      toast.success(t('success.passwordChange')); // Success toast
      setTimeout(() => {
        navigate('/role');
      }, 2000);
    } catch (err) {
      // setError(err.message || t('errors.genericError'));
      // toast.error(err.message || t('errors.genericError')); // Error toast
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="form-wrapper py-5">
        <div className="auth-container container">
          <div className='row'>
            <div className='col-lg-6'>
              <div className='card form-card p-4'>
                <h2 className='text-primary mb-4'>{t('firstTimeChangePassword.heading')}</h2>
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
                <form onSubmit={handleSubmit}>
                  
                  <div className='form-group'>
                    <label className="form-label text-dark">{t('firstTimeChangePassword.newPasswordLabel')}<sup>*</sup></label>
                    <input
                      className='form-control border-0 bg-light px-4'
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <label className="form-label text-dark">{t('firstTimeChangePassword.confirmPasswordLabel')}<sup>*</sup></label>
                    <input
                      className='form-control border-0 bg-light px-4'
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <button type="submit" className='btn btn-secondary py-3 px-5 mt-3' disabled={loading}>
                    {loading ? t('buttons.updatingPassword') : t('buttons.updatePassword')}
                  </button>
                </form>
              </div>
            </div>
            <div className='col-lg-6'>
              <img src={LoginFormImage} alt='Login Form' className='form-image' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstTimePasswordChangeForm;

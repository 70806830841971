

// src/pages/Home.jsx
import React from 'react';
import MainLayout from '../components/Layouts/MainLayout';
import InnerBanner from '../components/PagesComponents/InnerBanner';
import AboutUs from '../components/PagesComponents/AboutUs';
import AboutUs2 from '../components/PagesComponents/AboutUs2';
import importImage from '../utils/images/importImage';
import icon1 from '../assets/icons/aim.png'
import icon2 from '../assets/icons/deadline.png'
import icon3 from '../assets/icons/intelligence.png'
import icon4 from '../assets/icons/save.png'
const About = () => { 
  const secImage3 = importImage("section-image3.jpg");
  const secImage4 = importImage("section-image3.jpg");
  return(
    <>
  <MainLayout>
    <InnerBanner pageKey="about" />
     <AboutUs />
     <AboutUs2 />
     <section className="py-5">
      <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="row g-5">
          <div className="col-lg-7">
            <div className='mb-3'>
              <h5>Our Purpose</h5>
              <p> To make an impact on the industry by taking you on a digital journey. </p>
              </div>
              <div className='mb-3'>
              <h5>Our Vision</h5>
              <p> To make the textile industry organised on all levels. </p>
              </div>
              <div className='mb-3'>
              <h5>Our Mission</h5>
              <p> To make small yarn sellers and buyers empowered.</p>
              </div>
            </div>
            <div className="col-lg-5">
            <img
                  src={secImage3}
                  className="rounded"
                  data-wow-delay="0.9s"
                  style={{ objectFit: "", width: "100%", maxWidth: "500px" }}
                  alt="About Us"
                />
            </div>
            
          </div>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="row g-5">
          <div className="col-lg-7">
            <div className='mb-3'>
            <img
                  src={secImage4}
                  className="rounded"
                  data-wow-delay="0.9s"
                  style={{ objectFit: "", width: "100%", maxWidth: "500px" }}
                  alt="About Us"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <h5>Our Differentiators</h5>
              <ul className='cstm-list list-with-icon' style={{ listStyle: "none" }}>
                <li><img
                  src={icon1}
                  className="rounded mr-2"
                  data-wow-delay="0.9s"
                  style={{ objectFit: "", width: "100%", maxWidth: "30px" }}
                  alt="About Us"
                />Customer centric approach</li>
                <li><img
                  src={icon2}
                  className="rounded mr-2"
                  data-wow-delay="0.9s"
                  style={{ objectFit: "", width: "100%", maxWidth: "30px" }}
                  alt="About Us"
                />Industry experience </li>
                <li> <img
                  src={icon3}
                  className="rounded mr-2"
                  data-wow-delay="0.9s"
                  style={{ objectFit: "", width: "100%", maxWidth: "30px" }}
                  alt="About Us"
                />Prudence</li>
                <li> <img
                  src={icon3}
                  className="rounded mr-2"
                  data-wow-delay="0.9s"
                  style={{ objectFit: "", width: "100%", maxWidth: "30px" }}
                  alt="About Us"
                />Faster solutions</li>
              </ul>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  </MainLayout>
</>
);
};

export default About;


import React from "react";
import { useLanguage } from "../../contexts/LanguageContext"; // Import the language context
import { Link } from "react-router-dom";

const RoleCard = () => {
  // const { language } = useLanguage(); // Get the current language
  const { t } = useLanguage();
  

  return (
    <section className="role-sec py-5">
      <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
           <div className="role-card-container">
               <ul className="role-list">
                  <li>
                    <Link to="/buyer-dashboard" className="role-card">
                    <span className="role-icon"> 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-person-heart" viewBox="0 0 16 16">
                      <path d="M9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4m13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276Z"/>
                    </svg>                      
                      </span> <h4>{t('role.buyer')}</h4></Link>
                  </li>
                  <li>
                    <Link to="/seller-dashboard" className="role-card">
                    <span className="role-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z"/>
                      </svg>
                    </span> 
                    <h4>{t('role.seller')}</h4></Link>
                  </li>
               </ul>
           </div>
        </div>
      </div>
    </section>
  );
};

export default RoleCard;

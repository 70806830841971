import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import { useAuth } from "../../contexts/AuthContext"; // Import useAuth hook
import { showConfirmationAlert } from "../../utils/confirmationAlert"; // Import common confirmation alert
import LanguageSwitcher from "./LanguageSwitcher";

const Header = () => {
  const { t } = useLanguage();
  const location = useLocation();
  const { user, logout } = useAuth(); // Access user and logout from AuthContext
  console.log("user", user);

  const handleLogout = () => {
    showConfirmationAlert({
      message: t("header.confirmLogout", "Are you sure you want to logout?"),
      onConfirm: () => {
        logout();
        console.log("Logged out");
      },
      onCancel: () => {
        console.log("Logout cancelled");
      },
    });
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0 sticky-top shadow-sm">
        <Link to="/" className="navbar-brand p-0">
          <h2 className="text-primary mb-0">TOKOYARN</h2>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ml-auto py-0 mr-3">
            <Link
              to="/"
              className={`nav-item nav-link ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              {t("header.home")}
            </Link>
            <Link
              to="/about"
              className={`nav-item nav-link ${
                location.pathname === "/about" ? "active" : ""
              }`}
            >
              {t("header.about")}
            </Link>
            <Link
              to="/news"
              className={`nav-item nav-link ${
                location.pathname === "/news" ? "active" : ""
              }`}
            >
              {t("header.news", "News")}
            </Link>

            {/* Conditional Rendering Based on Login State */}
            {user ? (
              <div className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  id="userDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {user.role || "User"}
                </a>
                <ul className="dropdown-menu" aria-labelledby="userDropdown">
                  <li>
                    <Link to="/buyer-dashboard" className="dropdown-item">
                      {t("header.buyerDashboard")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/seller-dashboard" className="dropdown-item">
                      {t("header.sellerDashboard")}
                    </Link>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={handleLogout}>
                      {t("header.logout", "Logout")}
                    </button>
                  </li>
                </ul>
              </div>
            ) : (
              <>
                <Link
                  to="/login"
                  className={`nav-item nav-link ${
                    location.pathname === "/login" ? "active" : ""
                  }`}
                >
                  {t("header.login", "Login")}
                </Link>
                <Link
                  to="/register"
                  className={`nav-item nav-link ${
                    location.pathname === "/register" ? "active" : ""
                  }`}
                >
                  {t("header.register", "Register")}
                </Link>
              </>
            )}
          </div>
          <LanguageSwitcher />
        </div>
      </nav>
    </header>
  );
};

export default Header;
import React, { useEffect, useState } from "react";
import { useLanguage } from "../../contexts/LanguageContext";
import { translations } from "../../i18n";
import { useNavigate } from 'react-router-dom';
import { getApi } from '../../utils/api/api'; // Import your API function here

const Products = () => {
  const navigate = useNavigate();
  const baseUrl = "https://backend.tokoyarn.com/public/";
  const { language } = useLanguage(); // Get current language
  const [productsData, setProductsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [loading, setLoading] = useState(true);

  // Fetch products data from API
  const fetchProducts = async () => {
    try {
      const response = await getApi("buyer/get-latest-product"); // Adjust the API URL accordingly
      console.log("Fetched products:", response.data); // Log the response data
      setProductsData(response.data.product.data); // Set the fetched data
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);


  // Pagination logic
  const totalPages = Math.ceil(productsData?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentProducts = Array.isArray(productsData) ? productsData.slice(startIndex, startIndex + itemsPerPage) : [];
  console.log("currentProducts::", currentProducts);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleBidClick = (product) => {
    navigate(`/buyer-dashboard/${product.id}`, { state: { product } });
  };

  return (
    <section className="products-section py-5">
      <div className="container">

        {loading ? (
          <p>Loading...</p>
        ) : productsData.length > 0 ? (
          <div className="row">

            {currentProducts.map((product, index) => (
              <div className="col-md-12 mb-4" key={index}>
                <div className="card h-100">
                  <div className="row">
                    <div className="col-lg-4">
                      {product.images && JSON.parse(product.images).length ?

                        <img
                          key={0}
                          src={`${baseUrl + JSON.parse(product.images)[0]}`}
                          alt={`Product ${0 + 1}`}
                          className="img-fluid mb-2"
                        /> : ''

                      }

                    </div>
                    <div className="col-lg-8">
                      <div className="card-body">
                        <h5 className="card-title">{product.name}</h5>
                        <p className="card-text">
                          <strong>Price:</strong> {product.price}
                        </p>
                        <p className="card-text">
                          <strong>Details:</strong> {product.parameters}
                        </p>
                        <p className="card-text">{product.description}</p>
                        <div className="d-flex">
                          <a
                            href={product.link}
                            className="btn btn-primary mr-2"
                            rel="noopener noreferrer"
                          >
                            Buy
                          </a>
                          <button className="btn btn-secondary" onClick={() => handleBidClick(product)}>Bid</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
        <p>No Data found.</p>
        )
      }

        {/* Pagination */}
        {!loading && productsData?.length > 0 && (
          <nav aria-label="Products pagination">
            <ul className="pagination justify-content-center">
              {[...Array(totalPages)].map((_, i) => (
                <li
                  key={i}
                  className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                  onClick={() => handlePageChange(i + 1)}
                >
                  <button className="page-link">{i + 1}</button>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </section>
  );
};

export default Products;
// src/components/Layouts/MainLayout.jsx
import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

const MainLayout = ({ children }) => (
  <>
    <Header />
    <main className='main-layout'>{children}</main>
    <Footer />
  </>
);

export default MainLayout;

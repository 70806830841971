import React, { useState } from 'react';
import { useLanguage } from "../../contexts/LanguageContext";
import { postApi } from '../../utils/api/api';
import importImage from "../../utils/images/importImage";
import { showToast } from '../../utils/toast';

const ForgotPasswordForm = () => {
  const LoginFormImage = importImage("login-form.jpg");
  const { t } = useLanguage();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ email: '' }); // State for validation errors

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!email) {
      formErrors.email = t('errors.emailRequired');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = t('errors.invalidEmail');
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Validate form before submission

    setLoading(true);
    try {
      const response = await postApi('/forgot-password', { email });

      const message = response?.message || t('success.forgotPassword', 'Password reset email sent!');
      showToast('success', message);
    } catch (err) {
      const errorMessage = err.response?.data?.data || t('errors.forgotPasswordFailed', 'Failed to send reset email.');
      setErrors({ ...errors, email: errorMessage });
      // showToast('error', errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-wrapper py-5">
      <div className="auth-container container">
        <div className='row'>
          <div className='col-lg-6'>
            <div className='card form-card p-4'>
              <h2 className='text-primary mb-4'>{t('titles.forgotPassword')}</h2>
              <form onSubmit={handleSubmit}>
                <div className='form-group'>
                  <label className="form-label text-dark">
                    {t('forgotPassword.emailLabel')}<sup>*</sup>
                  </label>
                  <input
                    className='form-control border-0 bg-light px-4'
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrors({ ...errors, email: '' }); // Clear error on change
                    }}
                  />
                  {errors.email && <small className="text-danger mt-1">{errors.email}</small>}
                </div>
                <button type="submit" className='btn btn-secondary py-3 px-5 mt-3' disabled={loading}>
                  {loading ? t('buttons.sending', 'Sending...') : t('buttons.send', 'Send')}
                </button>
              </form>
              <p className="mt-3">
                <a href="/login">{t('forgotPassword.login', 'Login')}</a>
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <img src={LoginFormImage} alt={t('alt.loginFormImage', 'Login Form')} className='form-image' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
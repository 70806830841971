import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import { useAuth } from "../../contexts/AuthContext";

const DashboardSidebar = () => {
  const { t } = useLanguage();
  const location = useLocation();
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  // Determine dashboard type based on URL
  const isBuyerDashboard = location.pathname.startsWith("/buyer-dashboard");
  const isSellerDashboard = location.pathname.startsWith("/seller-dashboard");

  return (
    <div className="dashboard-sidebar">
      <ul className="sidebar-menu-list">
        {/* Buyer Dashboard Links */}
        {isBuyerDashboard && (
          <>
            <li>
              <Link
                to="/buyer-dashboard"
                className={`nav-item nav-link ${
                  location.pathname === "/buyer-dashboard" ? "active" : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.buyerDashboard", "Buyer Dashboard")}
              </Link>
            </li>
            <li>
              <Link
                to="/buyer-dashboard/buyer-bids"
                className={`nav-item nav-link ${
                  location.pathname === "/buyer-dashboard/buyer-bids"
                    ? "active"
                    : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.buyerBids", "Buyer Bids")}
              </Link>
            </li>
            {/* Uncomment if needed */}
            {/* <li>
              <Link
                to="/buyer-dashboard/special-feature"
                className={`nav-item nav-link ${
                  location.pathname === "/buyer-dashboard/special-feature"
                    ? "active"
                    : ""
                }`}
              >
                {t(
                  "dashboardSideNav.navMenu.buyerSpecialFeature",
                  "Special Feature"
                )}
              </Link>
            </li> */}
          </>
        )}

        {/* Seller Dashboard Links */}
        {isSellerDashboard && (
          <>
            <li>
              <Link
                to="/seller-dashboard"
                className={`nav-item nav-link ${
                  location.pathname === "/seller-dashboard" ? "active" : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.sellerDashboard", "Seller Dashboard")}
              </Link>
            </li>
            <li>
              <Link
                to="/seller-dashboard/add-inventory"
                className={`nav-item nav-link ${
                  location.pathname === "/seller-dashboard/manage-products"
                    ? "active"
                    : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.addInventory", "Add Inventory")}
              </Link>
            </li>
            <li>
              <Link
                to="/seller-dashboard/seller-bids"
                className={`nav-item nav-link ${
                  location.pathname === "/seller-dashboard/seller-bids"
                    ? "active"
                    : ""
                }`}
              >
                {t("dashboardSideNav.navMenu.sellerBids", "Seller Bids")}
              </Link>
            </li>
          </>
        )}

        {/* Role Link - Show only if not on Seller or Buyer Dashboard */}
        {!isSellerDashboard && !isBuyerDashboard && (
          <li>
            <Link
              to="/role"
              className={`nav-item nav-link ${
                location.pathname === "/role" ? "active" : ""
              }`}
            >
              {t("dashboardSideNav.navMenu.role", "Role")}
            </Link>
          </li>
        )}

        {/* Common Links */}
        <li>
          <Link
            to="/profile"
            className={`nav-item nav-link ${
              location.pathname === "/profile" ? "active" : ""
            }`}
          >
            {t("dashboardSideNav.navMenu.profile", "Profile")}
          </Link>
        </li>
        {/* Uncomment if needed */}
        {/* <li>
          <Link
            to="/orders"
            className={`nav-item nav-link ${
              location.pathname === "/orders" ? "active" : ""
            }`}
          >
            {t("dashboardSideNav.navMenu.orders", "Orders")}
          </Link>
        </li> */}
        <li>
          <Link
            to=""
            className="nav-item nav-link"
            onClick={handleLogout}
          >
            {t("dashboardSideNav.navMenu.logout", "Logout")}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default DashboardSidebar;

// import React from 'react';
// import Header from '../Common/Header';
// import Footer from '../Common/Footer';

// const DashboardLayout = ({ children }) => (
//   <>
//     <Header />
//     <main>{children}</main>
//     <Footer />
//   </>
// );

// export default DashboardLayout;



import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

const DashboardLayout = ({children}) => (
  <>
    <Header />
    <main>
      <Outlet /> 
      {children} {/* This renders the nested route components */}
    </main>
    <Footer />
  </>
);

export default DashboardLayout;

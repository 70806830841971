import React from 'react';
// import DashboardLayout from '../components/Layouts/DashboardLayout';
import DashboardSidebar from '../components/DashboardComponents/DashboardSidebar';
import InnerBanner from '../components/PagesComponents/InnerBanner';
import AddInventoryForm from '../components/DashboardComponents/AddInventoryForm';
const AddInventory = () => {
 return (
    <>
<InnerBanner pageKey="addInventory" />

      <section className='dashboard py-4'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-9'>
                   <AddInventoryForm/> 
                </div>
                <div className='col-lg-3'>
                    <DashboardSidebar/>
                </div>
            </div>
        </div>
      </section>
    </>
 );

};

export default AddInventory;
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Function to show a confirmation alert for logout or delete
export const showConfirmationAlert = ({ message, onConfirm, onCancel }) => {
  const toastId = toast(
    ({ closeToast }) => (
      <div>
        <h4 className="text-dark mb-3">{message}</h4>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            onClick={() => {
              onConfirm();
              toast.dismiss(toastId); // Properly dismiss the toast
            }}
            style={{
              padding: "5px 20px",
              marginRight: "10px",
              backgroundColor: "#4CAF50",
              color: "#fff",
              border: "none",
              cursor: "pointer",
              borderRadius: "5px",
            }}
          >
            Yes
          </button>
          <button
            onClick={() => {
              onCancel();
              toast.dismiss(toastId); // Properly dismiss the toast
            }}
            style={{
              padding: "5px 20px",
              backgroundColor: "#f44336",
              color: "#fff",
              border: "none",
              cursor: "pointer",
              borderRadius: "5px",
            }}
          >
            No
          </button>
        </div>
      </div>
    ),
    {
      position: "top-center",
      autoClose: false, // Keep toast open until interaction
      closeOnClick: false, // Disable closing on click
      draggable: false, // Disable dragging
      closeButton: false, // Disable close button
      hideProgressBar: true, // Hide progress bar
    }
  );
};
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'; // Import Navigate
import { AuthProvider, useAuth } from './contexts/AuthContext'; // Import useAuth
import { LanguageProvider } from './contexts/LanguageContext';
import ProtectedRoute from './components/ProtectedRoute';
import DashboardLayout from './components/Layouts/DashboardLayout';

import { publicRoutes, protectedRoutes, dashboardRoutes } from './routes/Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginRedirect = ({ children }) => {
  const { user } = useAuth(); // Use useAuth to access user

  if (user) {
    // Redirect to homepage if the user is already logged in
    return <Navigate to="/role" />;
  }

  return children;
};

const App = () => {
  return (
    <Router>
      <ToastContainer />
      <LanguageProvider>
        <AuthProvider>
          <Routes>
            {/* Public Routes */}
            {publicRoutes.map(({ path, element }) => (
              <Route
                key={path}
                path={path}
                element={
                  path === '/login' ? (
                    <LoginRedirect>{element}</LoginRedirect>
                  ) : (
                    element
                  )
                }
              />
            ))}

            {/* Protected Routes */}
            {protectedRoutes.map(({ path, element }) => (
              <Route
                key={path}
                path={path}
                element={<ProtectedRoute>{element}</ProtectedRoute>}
              />
            ))}

            {/* Role-Based Dashboard Routes */}
            <Route element={<DashboardLayout />}>
              {dashboardRoutes.map(({ path, element, role }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <ProtectedRoute role={role}>{element}</ProtectedRoute>
                  }
                />
              ))}
            </Route>
          </Routes>
        </AuthProvider>
      </LanguageProvider>
    </Router>
  );
};

export default App;
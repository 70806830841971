import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import News from '../pages/News';
import NewsDetail from '../pages/NewsDetail';
import Login from '../pages/Login';
import Register from '../pages/Register';
import NotFound from '../pages/NotFound';
import ForgotPassword from '../pages/ForgotPassword';
import FirstTimePasswordChange from '../pages/FirstTimePasswordChange';
import Role from '../pages/Role';

import DashboardLayout from '../components/Layouts/DashboardLayout';
import SellerDashboard from '../userDashboardPages/SellerDashboard';
import BuyerDashboard from '../userDashboardPages/BuyerDashboard';
import AdminDashboard from '../userDashboardPages/AdminDashboard';
import Profile from '../userDashboardPages/Profile';
import ProductDetail from '../userDashboardPages/ProductDetail';
import AddInventory from '../userDashboardPages/AddInventory';
import EditInventory from '../userDashboardPages/EditInventory';
import SellerBid from '../userDashboardPages/SellerBid';
import BuyerBid from '../userDashboardPages/BuyerBid';

export const publicRoutes = [
  { path: '/', element: <Home /> },
  { path: '/about', element: <About /> },
  { path: '/contact', element: <Contact /> },
  { path: '/news', element: <News /> },
  { path: '/news-detail/:id', element: <NewsDetail /> },
  { path: '/login', element: <Login /> },
  { path: '/register', element: <Register /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '*', element: <NotFound /> },
];

export const protectedRoutes = [
  {
    path: '/first-time-password-change',
    element: <FirstTimePasswordChange />,
  },
  {
    path: '/role',
    element: <Role />,
  },
];

export const dashboardRoutes = [
  {
    path: '/seller-dashboard',
    element: <SellerDashboard />,
    role: 'user',
  },
  {
    path: '/seller-dashboard/add-inventory',
    element: <AddInventory />,
    role: 'user',
  },
  {
    path: '/seller-dashboard/seller-bids',
    element: <SellerBid />,
    role: 'user',
  },
  {
    path: '/seller-dashboard/:id',
    element: <EditInventory />,
    role: 'user',
  },
  {
    path: '/buyer-dashboard',
    element: <BuyerDashboard />,
    role: 'user',
  },
  {
    path: '/buyer-dashboard/buyer-bids',
    element: <BuyerBid />,
    role: 'user',
  },
  {
    path: '/buyer-dashboard/:id',
    element: <ProductDetail />,
    role: 'user',
  },
  {
    path: '/admin-dashboard',
    element: <AdminDashboard />,
    role: 'admin',
  },
  {
    path: '/profile',
    element: <Profile />,
    role: 'user',
  },
];
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getApi } from '../../utils/api/api';
import { useNavigate } from 'react-router-dom';

const BuyerBidList = () => {
  const baseUrl = "https://backend.tokoyarn.com/public/";
  const { user } = useAuth();
  const navigate = useNavigate();
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch bids
  const fetchBids = async () => {
    setLoading(true);
    try {
      const response = await getApi(`buyer/on-product-bid`);
      console.log('response:: ', response);
      setBids(response.data.bid.data);
    } catch (err) {
      console.error('Failed to fetch bids.', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBids();
  }, []);

  return (
    <div className="container py-5">
      <h2 className="text-primary mb-4">Buyer Bid List</h2>
      {loading ? (
        <p>Loading...</p>
      ) : bids.length > 0 ? (
        <div className="row">
          {bids.map((bid) => (
            <div className="col-md-12 mb-4" key={bid.product.id}>
              <div className="card p-3">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="image-grid">
                      {bid.product.images && JSON.parse(bid.product.images).length ? (
                        <img
                          key={0}
                          src={`${baseUrl + JSON.parse(bid.product.images)[0]}`}
                          alt={`Product ${0 + 1}`}
                          className="img-fluid mb-2"
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <h4>{bid.product.name}</h4>
                    <div className="product-specs">
                      <p>Material: {bid.product.material}</p>
                      <p>Quantity: {bid.product.qty} {bid.product.qty_type}</p>
                      <p>Price: {bid.product.price}</p>
                      {bid.product.P1 && <p>P1: {bid.product.P1 || '-'}</p>}
                      {bid.product.P2 && <p>P2: {bid.product.P2 || '-'}</p>}
                      {bid.product.P3 && <p>P3: {bid.product.P3 || '-'}</p>}
                      {bid.product.P4 && <p>P4: {bid.product.P4 || '-'}</p>}
                      <p>Bid Amount: {bid.value}</p>
                    </div>
                    <p>Description: {bid.product.description}</p>
                    {/* <button
                      className="btn btn-secondary"
                      onClick={() => navigate(`/seller-dashboard/${bid.id}`, { state: { bid } })}
                    >
                      View Details
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No bids found.</p>
      )}
    </div>
  );
};

export default BuyerBidList;
import React, { createContext, useState, useContext } from 'react';
import { translations } from '../i18n';

// Create the LanguageContext
const LanguageContext = createContext();

// Custom hook for using the language context
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Default language is 'en'

  // Change language function
  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  // Translation function
  const t = (key) => {
    return key.split('.').reduce((acc, part) => acc?.[part], translations[language]) || key;
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
